<mat-form-field style="margin: 0 5px;">
  <mat-label>{{ placeholder }}</mat-label>
  <input type="text"
         placeholder="{{ placeholder ?? name }}"
         [attr.aria-label]="placeholder"
         matInput
         name="{{ name }}"
         disabled="{{ disabled }}"
         (ngModelChange)="ngChange($event)"
         [(ngModel)]="obj[name]"
         [matAutocomplete]="auto">
  <button *ngIf="!disabled && obj[name] !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete panelWidth="fit-content" autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">{{ option }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
