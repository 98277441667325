import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgForOf,
    MatIcon,
    MatIconButton,
    NgIf
  ],
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent {
  @Input({ required: true }) name;
  @Input() options: string[] = [];//[];
  @Input() placeholder: string;
  @Input() obj: any = {};
  @Input() disabled = false;
  filteredOptions: string[];
  @Output() onChange = new EventEmitter<any>();

  private _filter(value: any): string[] {
    const filterValue = value?.toLowerCase() ?? '';

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  clearSelection() {
    this.obj[this.name] = '';
    this.filteredOptions = this._filter( '');
  }

  ngChange(value: any) {
    this.filteredOptions = this._filter(value || '');
    this.obj[this.name] = value;
    this.onChange.emit(value);
  }
}
